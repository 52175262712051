import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { useFooter } from "../../shared/hooks/useFooter";
import { SkyfriLogo } from "../skyfri-logo/SkyfriLogo";
import './style.css';
import {ButtonHTMLAttributes, PropsWithChildren} from "react";
import {useNavigate, useNavigation} from "react-router-dom";

const NavigationButton = ({ children, ...props } : PropsWithChildren & ButtonHTMLAttributes<any>) => {
    return (
        <button className="navigation-button" {...props}>
            {children}
        </button>
    )
}

export const FooterWithArrows = () => {
    const navigate = useNavigate();
    const {
        setScreenType,
        secondaryLogo: deliveredByLogoLink,
        secondaryLogoB64: deliveredByBase64
    } = useFooter();

    return (
        <div className="footer-container mx-12 min-h-16 relative">
            <div className="nav-buttons">
                <NavigationButton onClick={() => navigate(-1)}>
                    <ChevronLeft sx={{ fontSize: '2rem' }}></ChevronLeft>
                    Back
                </NavigationButton>
                <NavigationButton onClick={() => setScreenType()}>
                    Next
                    <ChevronRight sx={{ fontSize: '2rem' }}></ChevronRight>
                </NavigationButton>
            </div>

            <div className="footer-logos">
                {(deliveredByLogoLink || deliveredByBase64) && <>
                    <div className="delivered-by">
                        Delivered by
                        <img
                            src={deliveredByLogoLink || deliveredByBase64}
                            alt="Delivered by logo" />
                    </div>
                    <div className="divider"></div>
                </>}

                <div className="powered-by">
                    Powered by
                    <SkyfriLogo className="skyfri-logo" />
                </div>
            </div>
        </div>
    )
}