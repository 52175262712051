import {Suspense, lazy} from 'react';
import {useCapacityWidget} from './components/capacityWidget/useCapacityWidget';
import {useKpiWidget} from './components/kpiWidget/useKpiWidget';
import {useAggregateScreen} from './useAggregateScreen';
import {useSearchParams} from 'react-router-dom';
import {useGraphWidget} from './components/graphWidget/useGraphWidget';
import {useGraphWidgetV2} from './components/graphWidgetV2/useGraphWidgetV2';
import {useGraphWidgetV3} from './components/graphWidgetV3/useGraphWidgetV3';
import {sharedStateSelector} from '../../features/sharedState/sharedSlice';
import {useSelector} from 'react-redux';
import {useEnvironmentalImpactWidget} from './components/environmentalImpactWidget/useEnvironmentalImpactWidget';
import './aggregateScreen.css';

const CapacityWidget = lazy(() =>
    import("../../shared/components/capacityWidget").then((module) => ({
        default: module.CapacityWidget,
    }))
);

const KpiWidget = lazy(() =>
    import("../../shared/components/kpiWidget").then((module) => ({
        default: module.KpiWidget,
    }))
);

const KpiWidgetV2 = lazy(() =>
    import("../../shared/components/kpiWidgetV2").then((module) => ({
        default: module.KpiWidgetV2,
    }))
);

const GraphWidget = lazy(() =>
    import("../../shared/components/graphWidget").then((module) => ({
        default: module.GraphWidget,
    }))
);

const StackedGraphWidget = lazy(() =>
    import("../../shared/components/stackedGraphWidget").then((module) => ({
        default: module.StackedGraphWidget,
    }))
);

const EnvironmentalImpactWidget = lazy(() =>
    import("./components/environmentalImpactWidget").then((module) => ({
        default: module.EnvironmentalImpactWidget,
    }))
);


export const AggregateScreen = () => {
    const [url] = useSearchParams()
    const version = url.get("version")
    const {graphLoading} = useSelector(sharedStateSelector)

    useAggregateScreen();

    const checkKpiVersion = (version) => {
        switch (version) {
            case "1":
                return <KpiWidget customHook={useKpiWidget} comp='agg'/>
            case "2":
            case "5":
                return <KpiWidgetV2 customHook={useKpiWidget}/>
            case "3":
                return <KpiWidgetV2 version={version} customHook={useKpiWidget}/>
            case "4":
                return <KpiWidget customHook={useKpiWidget} comp='agg'/>
            default:
                return null
        }
    }

    const checkGraphVersion = (version) => {
        switch (version) {
            case "1":
            case "4":
                return <GraphWidget
                    customHook={useGraphWidget}
                    marginRight={40}
                    marginLeft={70}/>
            case "2":
            case "5":
                return <GraphWidget
                    customHook={useGraphWidgetV2}
                    marginRight={40}
                    marginLeft={70}
                />
            case "3":
                return <StackedGraphWidget
                    customHook={useGraphWidgetV3}
                    marginRight={40}
                    marginLeft={70}
                />
            default:
                return null
        }
    }

    const checkPlantsPower = (version) => {
        if (version === "3") {
            return (
                <div className='grid grid-cols-2'>
                    <div className='col-span-1'>
                        {checkKpiVersion(version)}
                    </div>
                </div>
            )
        } else {
            return checkKpiVersion(version)
        }
    }

    const checkEnvironmentalImpactWidgetsVersion = (version) => {

        if (version === "4" || version === "5") {
            return (
                <EnvironmentalImpactWidget customHook={useEnvironmentalImpactWidget}/>
            )
        } else {
            return null
        }
    }

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className="flex flex-col flex-1 h-full">
                <div className='flex gap-14 mb-8'>
                    <CapacityWidget comp='agg' customHook={useCapacityWidget}/>
                    {checkPlantsPower(version)}
                </div>

                <div className='contents flex-1 min-h-0'>
                    {checkGraphVersion(version)}
                </div>

                {!graphLoading && checkEnvironmentalImpactWidgetsVersion(version)}
            </div>
        </Suspense>
    )
}
