import { useSelector } from 'react-redux';
import { sharedStateSelector } from '../../features/sharedState/sharedSlice';
import { useCapacityWidgetAms } from './components/capacityWidgetAms/useCapacityWidgetAms';
import { Suspense, lazy, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { checkHeightForSiteGraph, checkNegative, screenBottomGap } from '../../shared/utils/utils';
import './plantScreenAmsV4.css';
import { useEnvironmentalImpactWidget } from './components/environmentalImpactWidget/useEnvironmentalImpactWidget';
import { useKpiWidgetAmsV2 } from '../plantScreenAmsV2/components/kpiWidgetAmsV2/useKpiWidgetAmsV2';

const CapacityWidget = lazy(() =>
    import("../../shared/components/capacityWidget").then((module) => ({
        default: module.CapacityWidget,
    }))
);

const GraphWidgetAms = lazy(() =>
    import("./components/graphWidetAms").then((module) => ({
        default: module.GraphWidgetAms,
    }))
);

const KpiWidgetV4 = lazy(() =>
    import("../../shared/components/kpiWidgetV2").then((module) => ({
        default: module.KpiWidgetV2,
    }))
);
const EnvironmentalImpactWidget = lazy(() =>
    import("./components/environmentalImpactWidget").then((module) => ({
        default: module.EnvironmentalImpactWidget,
    }))
);

export const PlantScreenAmsV4 = () => {
    const { colors, fullScreen } = useSelector(sharedStateSelector)
    const [availableHeight, setAvailableHeight] = useState(0);
    const divRef = useRef(null);

    useLayoutEffect(() => {
        document.body.style.backgroundColor = colors?.backgroundColor;
    }, [colors]);

    const calculateAvailableHeight = () => {
        if (divRef.current) {
            const elementBottom = divRef.current.getBoundingClientRect().bottom;
            const viewportHeight = window.innerHeight;
            setAvailableHeight(availableHeight => checkNegative(availableHeight + ((viewportHeight - elementBottom) - screenBottomGap())));
        }
    };

    useMemo(() => {
        if (!divRef.current)
            return

        //initial calculation
        calculateAvailableHeight();

        // Re-calculate available height on window resize
        window.addEventListener('resize', calculateAvailableHeight);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', calculateAvailableHeight);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [divRef.current]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className='grid grid-cols-12'>
                <div className='col-span-12 flex gap-14 mb-8'>
                    <CapacityWidget comp='plant-ams' customHook={useCapacityWidgetAms} />
                    <KpiWidgetV4 customHook={useKpiWidgetAmsV2} />
                </div>

                <div className='col-span-12' ref={divRef} style={{ height: availableHeight }}>
                    <div>
                        <GraphWidgetAms fullScreen={fullScreen} height={availableHeight - checkHeightForSiteGraph()} />
                    </div>
                    <div>
                        <EnvironmentalImpactWidget customHook={useEnvironmentalImpactWidget} />
                    </div>
                </div>
            </div>
        </Suspense>
    )
}
