import {Suspense, useLayoutEffect} from 'react'
import {useSelector} from 'react-redux';
import {sharedStateSelector} from '../../features/sharedState/sharedSlice';
import {LocationWidget} from '../../shared/components/locationWidget';
import {useLocationWidget} from './locationWidget/uselocationWidget';
import {CommissionedWidget} from '../../shared/components/commissionedWidget';
import {useCapacityCommissionWidget} from './capacityCommissionWidget/useCapacityCommissionWidget';
import {SiteImageWidget} from '../../shared/components/siteImageWidget';
import {useSiteImage} from './siteImage/useSiteImage';

export const SiteOverview = () => {
    const {colors} = useSelector(sharedStateSelector)

    useLayoutEffect(() => {
        document.body.style.backgroundColor = colors?.backgroundColor;
    }, [colors]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className='flex flex-col flex-1 h-full'>
                <div className='flex justify-between mb-8'>
                    <LocationWidget customHook={useLocationWidget}/>
                    <CommissionedWidget customHook={useCapacityCommissionWidget}/>
                </div>
                <SiteImageWidget customHook={useSiteImage}/>
            </div>
        </Suspense>
    )
}
