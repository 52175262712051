import moment from 'moment';
import momentTZ from 'moment-timezone'

export const getSelectedString = (option, type) => {
    let optionString = "";
    if (!option) return "";
    if (option.length === 1) {
        option?.forEach((element) => {
            optionString = optionString + element[type];
        });
    }
    if (option.length > 1) {
        option?.forEach((element) => {
            optionString = `${element[type]},` + optionString;
        });
        optionString = optionString.substring(0, optionString.length - 1);
    }
    return optionString;
};

export const autoRefreshApiTime = (callback, minutes: number) => {
    return setInterval(() => callback(), minutes * 60 * 1000);
}

export const convertUnitForGraphs = (val: number, unit?: string) => {
    let valToReturn;
    let unitToReturn;
    if (val > 1000) {
        if (unit === "kW") {
            valToReturn = (val / 1000).toFixed(2);
            unitToReturn = "MW"
            return valToReturn + " " + unitToReturn;
        }
        else if (unit === "kWh") {
            valToReturn = (val / 1000).toFixed(2);
            unitToReturn = "MWh"
            return valToReturn + " " + unitToReturn;
        }
        else return val.toFixed(2) + " " + unit
    }
    else return val.toFixed(2) + " " + unit;
}

export const kWOrMW = (val: number) => {
    if (val > 1000)
        return "MW";
    else
        return "kW";
}

export const kWhOrMWh = (val: number) => {
    if (val > 1000)
        return "MWh";
    else
        return "kWh";
}

export const convertkWToMW = (val: number) => {
    if (val > 1000)
        return val / 1000;
    else
        return val;
}

export const checkDate = (urlDate, globalDate) => {
    if (!urlDate) {
        if (!globalDate)
            return new Date().toISOString();
        return globalDate.toString();
    }
    else return urlDate;
}
export const convertTime = (timeStamp?: string | Date, format?: string) => {
    const time = moment(timeStamp, '').format(format);
    return time;
    ///
}

export const convertDateForCommission = (timeStamp?: string | Date, format?: string) => {
    const time = moment(timeStamp, 'DD-MM-YYYY').format(format);
    return time;
    ///
}

const pluralize = (count, noun) => {
    return count === 1 ? noun : noun + 's';
}

export const convertTotalTimeAgo = (minutes: number) => {
    if (minutes >= 60) {
        let hours = Math.floor(minutes / 60);
        minutes = minutes % 60;

        if (hours >= 24) {
            let days = Math.floor(hours / 24);
            hours = hours % 24;

            if (days > 0) {
                return `${days} ${pluralize(days, 'day')}`;
            } else {
                return `${hours} ${pluralize(hours, 'hour')}`;
            }
        } else {
            return `${hours} ${pluralize(hours, 'hour')}`;
        }
    } else {
        return `${minutes} min`;
    }
}

export const getCurrentDate = () => {
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();

    // This arrangement can be altered based on how we want the date's format to appear.
    return `${year}.${month}.${day}`; // 2021.7.23
}

export const getCurrentDateMonth = () => {
    const months = [
        'January', 'February', 'March', 'April',
        'May', 'June', 'July', 'August',
        'September', 'October', 'November', 'December'
    ];

    const currentDate = new Date();
    const day = currentDate.getDate();
    const monthIndex = currentDate.getMonth();
    const month = months[monthIndex];

    return `${day} ${month}`;
}

export const getTestCurrentDateTime = () => {
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();

    // This arrangement can be altered based on how we want the date's format to appear.
    return `${year}.${month}.${day} ${hours}:${minutes}`; // 2021.7.1 12:30
}

export function convertEnergy(valueInKWh, toFixed = 2) {
    if (valueInKWh < 1000) {
        const formattedValue = valueInKWh.toFixed(toFixed);
        return Number.isInteger(Number(formattedValue)) ? parseInt(formattedValue) : parseFloat(formattedValue);
    }
    if (valueInKWh < 1000000) {
        const valueInMWh = valueInKWh / 1000;
        const formattedValue = valueInMWh.toFixed(toFixed);
        return Number.isInteger(Number(formattedValue)) ? parseInt(formattedValue) : parseFloat(formattedValue);
    }
    if (valueInKWh < 1000000000) {
        const valueInGWh = valueInKWh / 1000000;
        const formattedValue = valueInGWh.toFixed(toFixed);
        return Number.isInteger(Number(formattedValue)) ? parseInt(formattedValue) : parseFloat(formattedValue);
    }

    const valueInTWh = valueInKWh / 1000000000;
    const formattedValue = valueInTWh.toFixed(toFixed);
    return Number.isInteger(Number(formattedValue)) ? parseInt(formattedValue) : parseFloat(formattedValue);
}

export function convertEnergyForGraph(valueInKWh, maxNumber, toFixed = 2) {
    if (maxNumber < 1000) {
        const formattedValue = valueInKWh.toFixed(toFixed);
        return Number.isInteger(Number(formattedValue)) ? parseInt(formattedValue) : parseFloat(formattedValue);
    }
    if (maxNumber < 1000000) {
        const valueInMWh = valueInKWh / 1000;
        const formattedValue = valueInMWh.toFixed(toFixed);
        return Number.isInteger(Number(formattedValue)) ? parseInt(formattedValue) : parseFloat(formattedValue);
    }
    if (maxNumber < 1000000000) {
        const valueInGWh = valueInKWh / 1000000;
        const formattedValue = valueInGWh.toFixed(toFixed);
        return Number.isInteger(Number(formattedValue)) ? parseInt(formattedValue) : parseFloat(formattedValue);
    }

    const valueInTWh = valueInKWh / 1000000000;
    const formattedValue = valueInTWh.toFixed(toFixed);
    return Number.isInteger(Number(formattedValue)) ? parseInt(formattedValue) : parseFloat(formattedValue);
}

export function convertEnergyUnits(valueInKWh) {
    if (valueInKWh < 1000) {
        return `kWh`;
    }
    if (valueInKWh < 1000000) {
        return `MWh`;
    }
    if (valueInKWh < 1000000000) {
        return `GWh`;
    }
    return `TWh`;
}
export function convertPowerUnits(valueInKWh) {
    if (valueInKWh < 1000) {
        return `kW`;
    }
    if (valueInKWh < 1000000) {
        return `MW`;
    }
    if (valueInKWh < 1000000000) {
        return `GW`;
    }
    return `TW`;
}

export const MWptoKWp = (value: number) => {
    if (value < 1000)
        return value % 1 === 0 ? value.toFixed(0) : value.toFixed(1);
    else
        return (value / 1000) % 1 === 0 ? (value / 1000).toFixed(0) : (value / 1000).toFixed(1);
}

export const MWptoKWpUnit = (value: number, type: 'ac' | 'dc') => {
    if (value < 1000)
        return `${type === 'ac' ? 'kW' : 'kWp'}`;
    else
        return `${type === 'ac' ? 'MW' : 'MWp'}`;

}

export const convertTimeZone = (tz?: string, format?: string, timeStamp?: string, zone?: string) => {
    const time = momentTZ(`${timeStamp}Z`);
    return zone
        ? time.tz(`${tz}`).format(`${format} ${zone}`)
        : time.tz(`${tz}`).format(`${format}`);
    ///
}

export const setSessionStorageValues = (credentialType: string, token: string) => {
    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i);

        // Retrieve the object from sessionStorage
        const objectString = sessionStorage.getItem(key);

        if (objectString) {
            // Parse the object from string to JavaScript object
            const object = parseJSON(objectString);

            // Check if the object has the specified credentialType
            if (object.credentialType && object.credentialType === credentialType) {
                // Update the secret value or any other properties as needed
                object.secret = token;

                // Save the modified object back to sessionStorage
                const updatedObjectString = parseString(object);
                if (updatedObjectString)
                    sessionStorage.setItem(key, updatedObjectString);
                console.log(`token updated successfully`)
            }
        }
    }
}

export const parseJSON = (inputString) => {
    try {
        const parsedData = JSON?.parse(inputString);
        return parsedData;
    } catch (error) {
        console.log(error, inputString, `error`)
    }
};

export const parseString = (inputObject) => {
    try {
        const parsedData = JSON?.stringify(inputObject);
        return parsedData;
    } catch (error) {
        console.log(error, `error`)
    }
};

export const getTodaysDate = () => {
    const today = new Date();
    today.setDate(today.getDate());
    return today.toISOString().slice(0, 10);
}

export const getDate30DaysAgo = () => {
    const today = new Date();
    const date30DaysAgo = new Date(today);
    date30DaysAgo.setDate(today.getDate() - 30);
    return date30DaysAgo.toISOString().slice(0, 10);
}

export const singleDigitToDouble = (digit: number) => {
    return digit % 1 === 0 ? digit.toFixed(0) : digit.toFixed(1);
}

export const checkNull = (val: string) => {
    if (val === null || val === '0') {
        return '1';
    }
    else
        return val;
}

export const getLast12MonthAgo = () => {
    const today = moment();

    // Get the month 12 months ago
    const twelveMonthsAgo = today.clone().subtract(12, 'months').format('YYYY-MM-DD');

    // Get the current month
    const currentMonth = today.format('YYYY-MM-DD');

    return {
        twelveMonthsAgo: twelveMonthsAgo,
        currentMonth: currentMonth
    }
}

export const checkNegative = (val: number) => {
    return val < 0 ? 0 : val;
}

export const screenBottomGap = () => {
    const width = window.innerWidth;
    if (width <= 950) {
        return 90
    } if (width <= 1080) {
        return 120
    } if (width <= 1440) {
        return 150
    } else {
        return 160
    }
}

export const getBaseRightValue = () => {
    const width = window.innerWidth;
    if (width <= 950) {
        return 100;
    } else if (width <= 1080) {
        return 150;
    } else {
        return 425;
    }
};

export const graphFontSize = () => {
    const width = window.innerWidth;
    if (width <= 950) {
        return "8px";
    } else if (width <= 1440 && width >= 1080) {
        return "16px";
    } else {
        return "18px";
    }
}

export const graphWithTimeFontSize = () => {
    const width = window.innerWidth;
    if (width <= 950) {
        return "6px";
    } else if (width <= 1199 && width >= 1024) {
        return "8px";
    } else if (width <= 1440 && width >= 1200) {
        return "11px";
    } else if (width <= 1650 && width >= 1441) {
        return "14px";
    } else if (width <= 1865 && width >= 1651) {
        return "16px";
    } else {
        return "20px";
    }
}

export const graphIconSize = () => {
    const width = window.innerWidth;
    if (width <= 950) {
        return "25px";
    } else if (width <= 1199 && width >= 1024) {
        return "35px";
    } else if (width <= 1440 && width >= 1200) {
        return "45px";
    } else {
        return "60px";
    }
}

export const checkHeightForSiteGraph = () => {
    const width = window.innerWidth;
    if (width <= 950) {
        return 70
    } if (width <= 1080) {
        return 60
    } if (width <= 1440) {
        return 70
    } else {
        return 80
    }
}


export const addCommasToNumber = (num) => {
    // Convert the number to a string
    if (!num) return "";
    let numStr = num?.toString();

    // Split the number into integer and decimal parts if it has a decimal point
    let [integerPart, decimalPart] = numStr?.split('.');

    // Use a regular expression to add commas to the integer part
    let formattedIntegerPart = integerPart?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    // If there's a decimal part, append it; otherwise, return the formatted integer part
    return decimalPart ? `${formattedIntegerPart}.${decimalPart}` : formattedIntegerPart;
}

export const convertEpochToHumanReadable = (epochTime, minutesToSubtract = 0) => {
    // Create a new Date object from the epoch time
    const date = new Date(epochTime * 1000); // Multiply by 1000 to convert seconds to milliseconds

    // Format the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes() - minutesToSubtract).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Combine the formatted parts into a readable string
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const fullDateTime = () => {
    // Create a new Date object from the epoch time
    const date = new Date(); // Multiply by 1000 to convert seconds to milliseconds

    // Format the date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Combine the formatted parts into a readable string
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}

export const addNulls = (arr) => {
    // Create a new array with null at the start and end
    if (!arr) return [];
    return [null, ...arr, null];
}
