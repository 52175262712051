import {
    AuthenticatedTemplate,
    MsalAuthenticationTemplate,
    MsalProvider,
    UnauthenticatedTemplate
} from "@azure/msal-react";
import {Provider} from "react-redux";
import {Router} from "../routes";
import rootStore from "../store/rootStore";
import {pca} from "../msal";
import {BrowserRouter} from "react-router-dom";
import {getCredentialsFromUrl, initializePasswordless, isUsingPasswordlessAuth} from "../shared/utils/authUtils";
import {InteractionType} from "@azure/msal-browser";
import {SIDEM_SCOPE} from "../shared/utils/scope";
import {useEffect, useState} from "react";
import {appInsights, appInsightsEnabled} from "../appInsights";

const AuthWrapper = ({children}) => {
    if (isUsingPasswordlessAuth()) {
        return children
    } else {
        return <MsalProvider instance={pca}>
            <UnauthenticatedTemplate>
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={{
                        scopes: ["profile", ...SIDEM_SCOPE],
                    }}
                    errorComponent={(err) => <div>{err.error.errorMessage}</div>}
                    loadingComponent={() => <div>Loading...</div>}
                ></MsalAuthenticationTemplate>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                {children}
            </AuthenticatedTemplate>
        </MsalProvider>
    }
}

export const MSAAPP = () => {
    const [isAuthInitialising, setIsAuthInitialising] = useState(true)
    const [authError, setAuthError] = useState(null)

    useEffect(() => {
        if (isUsingPasswordlessAuth()) {
            console.log("Using passwordless auth")
            initializePasswordless().then(() => {
                setIsAuthInitialising(false)
            }).catch((error) => {
                console.error(error)
                if (appInsightsEnabled) {
                    appInsights.trackException(error, {username: getCredentialsFromUrl().username})
                }
                setAuthError('There was unfortunately an error with the login. Page will refresh in 10 seconds.')
                // Hard refresh in 10 seconds
                setTimeout(() => window.location.reload(), 10_000);
            })
        } else {
            console.log("Using login auth")
            pca.initialize().then(() => {
                setIsAuthInitialising(false)
            });
        }
    }, []);

    if (!process.env.REACT_APP_CLIENT_ID) {
        return <h1>Unable to read the env file. Please add environments to the project!</h1>
    }

    return <Provider store={rootStore}>
        <BrowserRouter>
            {isAuthInitialising && !authError && <div>Loading...</div>}
            {!isAuthInitialising && !authError &&
                <AuthWrapper>
                    <Router/>
                </AuthWrapper>
            }

            {authError && <div>{authError}</div>}
        </BrowserRouter>
    </Provider>
};
