import {useEffect, useState} from 'react'
import {Routes, Route, useSearchParams} from 'react-router-dom'
import App from "../app/App";
import {NotFound} from '../app/NotFound';
import {
    useMsal,
} from "@azure/msal-react";
import {PlantScreen} from '../pages/plantScreen';
import {Header} from '../components/header';
import {PlantScreenAmsV2} from '../pages/plantScreenAmsV2/plantScreenAmsV2.component';
import {RolesMap} from '../shared/interfaces';
import {PlantScreenAmsV3} from '../pages/PlantScreenAmsV3/PlantScreenAmsV3.component';
import {PlantScreenAmsV4} from '../pages/PlantScreenAmsV4/PlantScreenAmsV4.component';
import {SiteOverview} from '../pages/siteOverview';
import {PlantMonthly} from '../pages/plantMonthly';
import {PlantYearly} from '../pages/plantYearly/plantYearly.component';
import {getRoles, isUsingPasswordlessAuth} from "../shared/utils/authUtils";
import {FooterWithProgressBar} from "../components/footer-with-navigation-arrows/FooterWithProgressBar";
import {useGetUserPreferencesQuery} from "../features/sharedActions/sharedApi";
import {AppContext, AppContextData} from "../providers/AppContext";
import {FooterWithArrows} from "../components/footer-with-arrows/FooterWithArrows";
import {useCurrentPlantInfo} from "../shared/hooks/useCurrentPlantInfo";

export const Router = () => {
    const [url] = useSearchParams();
    const [isSlidesPaused, setIsSlidesPaused] = useState<boolean>(false);
    const [roles, setRoles] = useState<RolesMap | null>(null);
    const {accounts} = useMsal();
    const {data: storedUserPreferences} = useGetUserPreferencesQuery();
    const userPreferences = {...storedUserPreferences}
    const {plantName} = useCurrentPlantInfo();

    if (userPreferences && url.get('useArrows') === 'true') {
        userPreferences.navigationArrowsEnabled = true;
    }

    useEffect(() => {
        // If we are using passwordless auth, we already have a token before app is rendered.
        if (isUsingPasswordlessAuth() || accounts.length > 0) {
            getRoles().then(roles => setRoles(roles))
        }
    }, [accounts.length]);

    const appContext: AppContextData = {
        plantName,
        isSlidesPaused,
        setIsSlidesPaused,
        userPreferences
    }

    return (
        <>
            {/* Wait until we have authenticated and roles are available. */}
            {roles && userPreferences && plantName && <>
                <AppContext.Provider value={appContext}>
                    <div className='px-12 flex flex-col h-full flex-1'>
                        <Header roles={roles}/>
                        <div className='mb-8 min-h-0 h-full'>
                            <Routes>
                                <Route index element={<App/>}/>
                                <Route path='/plant-screen-v1' element={<PlantScreen/>}/>
                                <Route path='/plant-screen-v2' element={<PlantScreen/>}/>
                                <Route path='/plant-screen-ams-v1'
                                       element={<PlantScreenAmsV3 includeEnvironmentalImpact={false}/>}/>
                                <Route path='/plant-screen-ams-v2' element={<PlantScreenAmsV2/>}/>
                                <Route path='/plant-screen-ams-v3' element={<PlantScreenAmsV3/>}/>
                                <Route path='/plant-screen-ams-v4' element={<PlantScreenAmsV4/>}/>
                                <Route path='/plant-screen-overview' element={<SiteOverview/>}/>
                                <Route path='/plant-monthly-v1' element={<PlantMonthly/>}/>
                                <Route path='/plant-yearly-v1' element={<PlantYearly/>}/>
                                <Route path='*' element={<NotFound/>}/>
                            </Routes>
                        </div>
                    </div>
                    {userPreferences?.fixedSlidesEnabled || userPreferences?.navigationArrowsEnabled ?
                        <FooterWithArrows  /> : <FooterWithProgressBar/>
                    }
                </AppContext.Provider>
            </>
            }
        </>
    )
}
