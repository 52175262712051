// @ts-nocheck
import { CustomCard } from '../../../components/customCard/customCard.component';
import { Loader } from '../loader/loader.component';
import { ReactComponent as LOCATION_ICON } from "../../icons/Location.svg";
import { MWptoKWp, MWptoKWpUnit } from '../../utils/utils';
import './capacityStyle.css';

interface Props {
    customHook?: () => any;
    comp: string
}
export const CapacityWidget = (props: Props) => {
    const { customHook, comp } = props

    const { numberOfPlants, dcCapacity, locationInfo, kpiLoading } = customHook()

    return (
            <CustomCard>
                <div className="sm:px-2 sm:py-1 md:px-3 md:py-2 px-6 py-4 text-white">
                    {
                        comp === 'agg' && numberOfPlants > 1 ?
                            <div className="grid grid-cols-12 my-2">
                                <div className="col-span-10 inline-block self-center font-bold plantsText">
                                    Number of plants
                                </div>
                                <div className="col-span-2 self-center font-semibold place-self-end numberOfPlants">
                                    {numberOfPlants}
                                </div>
                            </div>
                            :
                            <div className="grid grid-flow-col auto-cols-max my-2">
                                <div className="row-span-2 font-bold pr-3">
                                    <LOCATION_ICON className="locationIcon" />
                                </div>
                                <div className="self-center font-bold locationText">
                                    {
                                        kpiLoading ?
                                            <Loader />
                                            :
                                            locationInfo?.city ? locationInfo?.city + `, ` + locationInfo?.country : ''
                                    }
                                </div>
                            </div>
                    }
                </div>
                <hr className="border-solid border-2 border-cardBorder" style={{ borderTopWidth: 0 }} />
                <div className="sm:px-2 sm:py-1 md:px-3 md:py-2 px-6 py-4 text-white">
                    <div className="grid grid-cols-12 my-2">
                        <div className="col-span-7 inline-block self-center font-semibold dcCapacityText">
                            DC capacity
                        </div>
                        <div className="col-span-5 font-semibold dcCapacity flex items-baseline place-self-end">
                            <div>
                                {MWptoKWp(dcCapacity)}&nbsp;{MWptoKWpUnit(dcCapacity, 'dc')}
                            </div>
                        </div>
                    </div>
                </div>
            </CustomCard>
    )
}
