import {useGetPlantProfileInfoQuery} from "../../features/sharedActions/sharedApi";
import {useMemo} from "react";
import {useSearchParams} from "react-router-dom";

type PlantInfoData = {
    plantId: number,
    portfolioName: string,
    plantName: string,
}

// TODO: useHeader could make use of this, so we are not handling this state in two places.
export const useCurrentPlantInfo = (): PlantInfoData => {
    const [url] = useSearchParams();
    const plantId = url.get('plantId');
    const {data: plantInfo} = useGetPlantProfileInfoQuery();
    const info = useMemo(() => plantInfo?.filter(pi => pi.plantId == plantId)[0] ?? plantInfo?.[0], [plantInfo, plantId]);

    return info ?? { plantId: 0, plantName: 'Unknown name' };
}