import { useSelector } from 'react-redux';
import { sharedStateSelector } from '../../features/sharedState/sharedSlice';
import { useCapacityWidgetAmsV2 } from './components/capacityWidgetAmsV2/useCapacityWidgetAmsV2';
import { Suspense, lazy, useLayoutEffect } from 'react';
import { useKpiWidgetAmsV2 } from './components/kpiWidgetAmsV2/useKpiWidgetAmsV2';
import './plantScreenAmsV2.css'

const GraphWidgetV2 = lazy(() =>
    import("./components/graphWidgetAmsV2/graphWidgetAmsV2.component").then((module) => ({
        default: module.GraphWidgetAmsV2,
    }))
);

const KpiWidgetV2 = lazy(() =>
    import("../../shared/components/kpiWidgetV2").then((module) => ({
        default: module.KpiWidgetV2,
    }))
);

const CapacityWidget = lazy(() =>
    import("../../shared/components/capacityWidget").then((module) => ({
        default: module.CapacityWidget,
    }))
);

export const PlantScreenAmsV2 = () => {
    const { colors } = useSelector(sharedStateSelector)

    useLayoutEffect(() => {
        document.body.style.backgroundColor = colors?.backgroundColor;
    }, [colors]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className="flex flex-col flex-1 h-full">
                <div className='flex gap-14 mb-8'>
                    <CapacityWidget comp='plant-ams' customHook={useCapacityWidgetAmsV2} />
                    <KpiWidgetV2 customHook={useKpiWidgetAmsV2} />
                </div>

                <div className='flex-1 min-h-0'>
                    <GraphWidgetV2 />
                </div>
            </div>
        </Suspense>
    )
}
