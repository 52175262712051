// @ts-nocheck
import {ReactComponent as NOIMAGE} from '../../icons/NOIMAGE.svg';
import './siteImageWidgetStyle.css'

interface SiteImageWidgetProps {
    customHook?: () => any;
}

export const SiteImageWidget = (props: SiteImageWidgetProps) => {
    const {customHook} = props;
    const {plantImage} = customHook();

    if (!plantImage) {
        return (
            <div className='h-full w-full rounded-xl border-solid border-2 border-cardBorder bg-cardColor'
                 style={{display: 'flex', justifyContent: "center", borderRadius: "16px", alignItems: 'center'}}>
                <NOIMAGE/>
            </div>
        )
    } else {
        return <div className='h-full w-full rounded-2xl bg-cover bg-center' style={{backgroundImage: `url(${plantImage})`}}/>
    }
}
