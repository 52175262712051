import {ReactNode} from 'react';

interface CustomCardProps {
    children: ReactNode;
    height?: number | string;
}

export const CustomCard = ({ children, height = null}: CustomCardProps) => {
    return <div className="rounded-xl border-solid border-2 border-cardBorder overflow-hidden bg-cardColor" style={{ height: height || "fit-content" }}>
        {children}
    </div>;
}; 