/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useGetPlantProfileInfoLobbyQuery } from "../../../../features/aggregateScreen/aggregateScreenApi";
import { useSelector } from "react-redux";
import { sharedStateSelector } from "../../../../features/sharedState/sharedSlice";
import {useCurrentPlantInfo} from "../../../../shared/hooks/useCurrentPlantInfo";
import {useGetPlantLocationQuery} from "../../../../features/plantScreen/plantScreenApi";
interface Data {
    numberOfPlants: number;
    acCapacity: number;
    dcCapacity: number;
    locationInfo?: { country: string; city: string; };
    kpiLoading: boolean;
}

export const useCapacityWidget = (): Data => {
    const { plantId } = useCurrentPlantInfo();
    const { numberOfPlantsState } = useSelector(sharedStateSelector)

    const { data: plantsInfo } = useGetPlantProfileInfoLobbyQuery(
        { portfolioId: "all", plantId: "all" },
        { refetchOnMountOrArgChange: 10800 });

    const { data: locationInfo, isLoading: kpiLoading } = useGetPlantLocationQuery({ plantId }, {skip: !plantId || plantsInfo?.length > 1} );

    const [numberOfPlants, setNumberOfPlants] = useState(0);
    const [acCapacity, setAcCapacity] = useState(0);
    const [dcCapacity, setDcCapacity] = useState(0);
    // const [var1, setVar1] = useState<any>(undefined);

    useEffect(() => {
        if (!plantsInfo || !plantsInfo?.length) return
        getData()
    }, [plantsInfo])

    useEffect(() => {
        setNumberOfPlants(numberOfPlantsState)

        // if (numberOfPlantsState === 1) {
        //     plantsInfo[0].
        // }
    }, [numberOfPlantsState])

    const getData = () => {
        setAcCapacity(plantsInfo[0]?.acCapacityKw)
        setDcCapacity(plantsInfo[0]?.dcCapacityKw)
    }

    // just to create an error remove
    // this is because we want to get to the error page
    // console.log(var1.length, `var1.length`)

    return {
        numberOfPlants,
        acCapacity,
        dcCapacity,
        locationInfo,
        kpiLoading,
    };
};
