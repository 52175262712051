// @ts-nocheck
import {useState, ReactNode, useContext, useEffect} from 'react'
import screenfull from 'screenfull';
import useComponentVisible from '../../../../shared/components/clickAwayListener/clickAwayListener.component';

import {ReactComponent as Fullscreen} from '../../../../shared/icons/Fullscreen.svg';
import {ReactComponent as Logout} from '../../../../shared/icons/Logout.svg';
import {ReactComponent as Minimize} from "../../../../shared/icons/Minimize.svg";
import {ReactComponent as VerticalDots} from '../../../../shared/icons/Vertical_Dots.svg'
import {ReactComponent as PlayIcon} from '../../../../shared/icons/Play.svg'
import {ReactComponent as PauseIcon} from '../../../../shared/icons/Pause.svg'

import './dropdownButton.css'
import {AppContext} from "../../../../providers/AppContext";
import {RolesMap} from "../../../../shared/interfaces";

interface Props {
    children?: ReactNode;
    userName: string;
    logOut: () => void;
    roles: RolesMap;
}

export const DropdownButton = (props: Props) => {
    const {userName, logOut, roles} = props
    const {isSlidesPaused, setIsSlidesPaused, userPreferences} = useContext(AppContext)
    const [isFullScreen, setIsFullScreen] = useState(false)

    const {ref, isComponentVisible, setIsComponentVisible} = useComponentVisible(false);

    const toggleFullScreen = () => {
        if (screenfull.isEnabled) {
            screenfull.toggle().then(r => setIsFullScreen(true));
        }
    }

    useEffect(() => {
        const handler = () => {
            if (!screenfull.isFullscreen) {
                setIsFullScreen(false)
            }
        }

        screenfull.onchange(handler);

        return () => {
            screenfull.off('change', handler);
        }
    }, [setIsSlidesPaused]);

    const togglePaused = () => setIsSlidesPaused(!isSlidesPaused)

    return (
        <div ref={ref}>
            <button
                id="dropdown-button"
                style={{backgroundColor: "transparent"}}
                onClick={() => {
                    setIsComponentVisible(!isComponentVisible)
                    setTimeout(() => {
                        setIsComponentVisible(false)
                    }, 6000);
                }}
                className="max-w-lg rounded-xl border-solid border-2 border-cardBorder overflow-hidden bg-cardColorfont-medium text-sm buttonPadding text-center inline-flex items-center"
            >
                <VerticalDots className='verticalDots'/>
            </button>
            {
                isComponentVisible &&
                <>
                    <div
                        id="dropdown-menu"
                        className={`absolute dropdownIndex right-10 mt-2 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 space-y-1 p-2`}
                    >
                        <div
                            className="block px-4 py-2 text-primary buttonTextSize cursor-pointer">
                            {userName}
                        </div>
                        <hr className='pb-2'/>
                        <div className="flex px-4 py-2 cursor-pointer buttonTextSize" onClick={() => {
                            toggleFullScreen()
                            setIsComponentVisible(false)
                        }}>
                            {isFullScreen ? <Minimize className='logoSize'/> : <Fullscreen className='logoSize'/>} <span
                            className='text-primary pl-3'>{isFullScreen ? `Minimize` : `Full screen`}</span>
                        </div>
                        {
                            roles.SkyfriDemo && !userPreferences?.fixedSlidesEnabled &&
                            <div className="flex px-4 py-2 cursor-pointer buttonTextSize" onClick={() => {togglePaused()}}>
                                {isSlidesPaused ? <PlayIcon className='logoSize'/> : <PauseIcon className='logoSize'/>}
                                <span className='text-primary pl-3'>{isSlidesPaused ? "Play" : 'Pause'}</span>
                            </div>
                        }

                        <div className="flex px-4 py-2 cursor-pointer buttonTextSize pb-3" onClick={() => {
                            logOut()
                            setIsComponentVisible(false)
                        }}>
                            <Logout className='logoSize'/> <span className='text-primary pl-3'>Log out</span>
                        </div>
                    </div>
                </>
            }
        </div>
    )
}
