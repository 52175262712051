import { useSelector } from "react-redux"
import { sharedStateSelector } from "../../../features/sharedState/sharedSlice"
import './yearlyKpiWidget.css';
import { Loader } from "../loader/loader.component";

interface Props {
    customHook?: () => any;
}

export const YearlyKpiWidget = (props: Props) => {
    const { customHook } = props

    const { totalEnergy, totalEnergyUnit, isLoading } = customHook()

    const { colors } = useSelector(sharedStateSelector)

    return (
        <div className="flex gap-8">
            <div className="flex">
                <div className=" text-white flex-auto">
                    <div className="flex items-center">
                        <div className="rounded-full circleSize" style={{ backgroundColor: colors?.energyColor }}></div>
                        <div className="pl-3 font-semibold powerTypeText">Total energy production</div>
                    </div>
                    <div className="sm:pt-1 md:pt-2 pt-4 font-extrabold powerTypeValue">
                        {!isLoading ? totalEnergy : <Loader width={"50%"} />}</div>
                    <div className="sm:pt-1 md:pt-2 pt-3 font-semibold powerTypeUnit">{totalEnergyUnit}</div>
                </div>
            </div>
        </div>
    )
}
