import './style.css';
import {SkyfriLogo} from "../skyfri-logo/SkyfriLogo";
import {useFooter} from "../../shared/hooks/useFooter";
import {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {AppContext} from "../../providers/AppContext";

export const FooterWithProgressBar = () => {
    const {isSlidesPaused} = useContext(AppContext)
    const {
        nextScreenInfo,
        setScreenType,
        nextPlantInfo,
        secondaryLogo,
        secondaryLogoB64
    } = useFooter();
    const navigate = useNavigate();
    const [progressBarEnabled, setProgressBarEnabled] = useState(true);

    const handleOverlayClick = (e) => {
        e.preventDefault();
        const overlayWidth = e.currentTarget.offsetWidth;
        const clickX = e.clientX - e.currentTarget.getBoundingClientRect().left;

        if (clickX < overlayWidth / 2) {
            navigate(-1)
            setProgressBarEnabled(false);
        } else {
            setScreenType()
            setProgressBarEnabled(false);
        }
    };

    const handleKeyDown = useCallback((e) => {
        e.preventDefault();

        if (e.key === 'ArrowLeft') {
            navigate(-1);
            setProgressBarEnabled(false);
        } else if (e.key === 'ArrowRight') {
            setScreenType();
            setProgressBarEnabled(false);
        }
    }, [setScreenType, setProgressBarEnabled, navigate]);

    useEffect(() => {
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown)
        };
    }, [setScreenType, handleKeyDown]);

    useEffect(() => {
        if (isSlidesPaused) {
            setProgressBarEnabled(false)
            return
        }

        setProgressBarEnabled(true)

        const interval = window.setInterval(() => {
            setScreenType();
            // Triggers a rerender, so we can restart the css animation.
            setProgressBarEnabled(false);
        }, 30_000);

        return () => {
            clearInterval(interval);
        };
    }, [setScreenType, setProgressBarEnabled, isSlidesPaused]);

    return <footer className='min-h-16 max-h-16 sm:min-h-24 sm:max-h-24 flex-1 flex items-center relative bg-white bg-opacity-10'>
        {/* CSS animation has 30s animation hardcoded */}
        {progressBarEnabled && <div className="progress-bar progress-bar-animation"/>}
        <div className='px-12 w-full flex justify-between items-center relative h-full text-lg sm:flex-col sm:px-2 sm:text-sm sm:justify-center'>
            <p className='text-white'><span className='text-gray-300'>Next:</span> {nextPlantInfo()} {nextScreenInfo()}
            </p>
            <div className='flex text-nowrap items-center gap-3'>
                {(secondaryLogo || secondaryLogoB64) &&
                    <>
                        <span className='text-gray-300'>Delivered by</span>
                        <img src={secondaryLogo || secondaryLogoB64} alt="Delivered by logo" className='relative h-8'/>
                        <div className='w-0 border rounded-full h-7 opacity-30'/>
                    </>
                }
                <span className='text-gray-300'>Powered by</span>
                <SkyfriLogo className='relative h-10 w-20'/>
            </div>
        </div>
        <div className="overlay" onClick={handleOverlayClick}></div>
    </footer>
}
