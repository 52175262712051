/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useGetPlantLocationQuery, useGetPlantProfileInfoLobbyQuery } from "../../../../features/plantScreen/plantScreenApi";
import {useCurrentPlantInfo} from "../../../../shared/hooks/useCurrentPlantInfo";

interface Data {
    numberOfPlants: number;
    acCapacity: number;
    dcCapacity: number;
    locationInfo: { country: string; city: string; };
    kpiLoading: boolean;
}

export const useCapacityWidget = (): Data => {
    const { plantId, portfolioName } = useCurrentPlantInfo();

    const { data: plantsInfo } = useGetPlantProfileInfoLobbyQuery({ portfolioName, plantId });
    const { data: locationInfo, isLoading: kpiLoading } = useGetPlantLocationQuery({ plantId });

    const [numberOfPlants, setNumberOfPlants] = useState(0);
    const [acCapacity, setAcCapacity] = useState(0);
    const [dcCapacity, setDcCapacity] = useState(0);

    useEffect(() => {
        if (!plantsInfo || !plantsInfo?.length) return
        getData()
    }, [plantsInfo])

    const getData = () => {
        setNumberOfPlants(plantsInfo[0]?.numberOfPlants)
        setAcCapacity(plantsInfo[0]?.acCapacityKw)
        setDcCapacity(plantsInfo[0]?.dcCapacityKw)
    }

    return {
        numberOfPlants,
        acCapacity,
        dcCapacity,
        locationInfo,
        kpiLoading,
    };
};
