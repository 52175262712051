import {useSelector} from 'react-redux';
import {sharedStateSelector} from '../../features/sharedState/sharedSlice';
import {useCapacityWidgetAms} from './components/capacityWidgetAms/useCapacityWidgetAms';
import {useKpiWidgetAms} from './components/kpiWidgetAms/useKpiWidgetAms';
import {Suspense, lazy, useLayoutEffect} from 'react';
import './plantScreenAmsV3.css';
import {useEnvironmentalImpactWidget} from './components/environmentalImpactWidget/useEnvironmentalImpactWidget';

const CapacityWidget = lazy(() =>
    import("../../shared/components/capacityWidget").then((module) => ({
        default: module.CapacityWidget,
    }))
);

const SelfSustainabilityAms = lazy(() =>
    import("./components/selfSustainabilityAms").then((module) => ({
        default: module.SelfSustainabilityAms,
    }))
);

const GraphWidgetAms = lazy(() =>
    import("./components/graphWidetAms").then((module) => ({
        default: module.GraphWidgetAms,
    }))
);

const KpiWidget = lazy(() =>
    import("../../shared/components/kpiWidget").then((module) => ({
        default: module.KpiWidget,
    }))
);

const EnvironmentalImpactWidget = lazy(() =>
    import("./components/environmentalImpactWidget").then((module) => ({
        default: module.EnvironmentalImpactWidget,
    }))
);

export const PlantScreenAmsV3 = ({ includeEnvironmentalImpact = true}) => {
    const {colors, fullScreen} = useSelector(sharedStateSelector)

    useLayoutEffect(() => {
        document.body.style.backgroundColor = colors?.backgroundColor;
    }, [colors]);


    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className='grid grid-cols-12 mb-8 gap-4'>
                <div className='col-span-4'>
                    <CapacityWidget comp='plant-ams' customHook={useCapacityWidgetAms}/>
                </div>
                <div className='col-span-8'>
                    <KpiWidget customHook={useKpiWidgetAms} comp='plant-ams'/>
                </div>
            </div>


            <div className='grid grid-cols-12 gap-8'>
                <div className='col-span-4'>
                    <SelfSustainabilityAms fullScreen={fullScreen}/>
                </div>

                <div className='col-span-8 flex flex-col'>
                    <div className='contents graph-height-stretch'>
                        <GraphWidgetAms fullScreen={fullScreen}/>
                    </div>
                    {includeEnvironmentalImpact && <EnvironmentalImpactWidget customHook={useEnvironmentalImpactWidget}/>}
                </div>
            </div>
        </Suspense>
    )
}
