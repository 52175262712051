import {CustomCard} from '../../../components/customCard/customCard.component'
import {MWptoKWp, MWptoKWpUnit, convertDateForCommission} from '../../utils/utils'
import './commissionedWidgetStyle.css'

interface Props {
    customHook?: () => any;
}

export const CommissionedWidget = (props: Props) => {
    const {customHook} = props

    const {dcCapacity, capacityLoading, commissionLoading, commissionDate} = customHook()

    return (
        <CustomCard>
            <div className="sm:px-2 sm:py-1 md:px-3 md:py-2 px-6 py-4 text-white">
                <div className="grid grid-flow-col auto-cols-max my-2">
                    <div>
                        <div className='grid grid-flow-col auto-cols-max'>
                            <div className="self-center font-semibold dcCapacityText">
                                DC capacity:
                            </div>
                            <div className="font-semibold dcCapacity flex items-baseline">
                                {
                                    capacityLoading ?
                                        <div>&nbsp;Loading...</div>
                                        :
                                        <> &nbsp;{MWptoKWp(dcCapacity)}&nbsp;{MWptoKWpUnit(dcCapacity, 'dc')}</>
                                }
                            </div>
                        </div>
                    </div>

                    <div className='flex justify-center sm:px-2 md:px-3 px-6'>
                        <div className="inline-block rounded h-full w-0.5 self-stretch bg-white opacity-30"></div>
                    </div>
                    <div>
                        <div className='grid grid-flow-col auto-cols-max'>
                            <div className="self-center font-semibold dcCapacityText">
                                Commissioned:
                            </div>
                            <div className="font-semibold dcCapacity flex items-baseline">
                                {
                                    commissionLoading ?
                                        <div>&nbsp;Loading...</div>
                                        :
                                        <>&nbsp;{convertDateForCommission(commissionDate?.commissionDate, "MMM YYYY")}</>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </CustomCard>
    )
}
