import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {getToken} from "../../shared/utils/authUtils";
import {UserPreferences} from "./sharedApi.types";

const BASE_API_URL = process.env.REACT_APP_API_URL
    ? process.env.REACT_APP_API_URL
    : "https://skifryfpel-dev-apimngment.azure-api.net/";

const sidemApiUrl = `SkyfriSidemApi/v1`
const lobbyApiUrl = `SkyfriSidemApi/v1/LobbyScreen`
const lobbyScreenColors = `SkyfriSidemApi/v1/LobbyScreenColors`

export const sharedApi = createApi({
    reducerPath: "sharedApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_API_URL}`,
        prepareHeaders: async (headers) => {
            const token = await getToken();
            headers.set("authorization", `Bearer ${token}`);
            headers.set(
                "Ocp-Apim-Subscription-Key",
                process.env.REACT_APP_API_MKEY
            );
            headers.set("Content-Type", "text/json");
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getClientInfo: builder.query<any[], void>({
            query: () => `${sidemApiUrl}/ClientInfo`,
        }),
        getPlantProfileInfo: builder.query<any[], void>({
            query: () => `${lobbyApiUrl}/GetPortfoliosAndPlantsForLobbyScreen`,
        }),
        postWeatherInfo: builder.mutation({
            query: (ip) => ({
                url: `${sidemApiUrl}/GetWeatherByLocation`,
                method: "POST",
                body: JSON.stringify({ Ip: ip }),
            }),
        }),
        getLobbyScreenColors: builder.query({
            query: ({ userId }) => `${lobbyScreenColors}/GetLobbyScreenColors?userId=${userId}`,
        }),
        getUserPreferences: builder.query<UserPreferences, void>({
            query: () => `${sidemApiUrl}/LobbyScreenUserPreferences/GetMyLobbyScreenPreferences`,
        })
    }),
});

export const {
    useGetClientInfoQuery,
    useGetPlantProfileInfoQuery,
    usePostWeatherInfoMutation,
    useGetLobbyScreenColorsQuery,
    useGetUserPreferencesQuery
} = sharedApi;