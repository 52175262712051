import {createContext} from "react";
import {UserPreferences} from "../features/sharedActions/sharedApi.types";

export type AppContextData = {
    plantName?: string,
    isSlidesPaused?: boolean,
    setIsSlidesPaused?: (isPaused: boolean) => void,
    userPreferences?: UserPreferences,
}

export const AppContext = createContext<AppContextData>({})