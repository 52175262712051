import {Suspense, lazy, useLayoutEffect} from 'react'
import {useSelector} from 'react-redux';
import {sharedStateSelector} from '../../features/sharedState/sharedSlice';
import {useCapacityWidget} from './components/capacityWidget/useCapacityWidget';
import './plantScreen.css';

const CapacityWidget = lazy(() =>
    import("../../shared/components/capacityWidget").then((module) => ({
        default: module.CapacityWidget,
    }))
);

const KpiWidget = lazy(() =>
    import("./components/kpiWidget").then((module) => ({
        default: module.KpiWidget,
    }))
);

const SiteGraphWidget = lazy(() =>
    import("./components/siteGraphWidget").then((module) => ({
        default: module.SiteGraphWidget,
    }))
);

export const PlantScreen = () => {
    const {colors} = useSelector(sharedStateSelector)

    useLayoutEffect(() => {
        document.body.style.backgroundColor = colors?.backgroundColor;
    }, [colors]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className="flex flex-col flex-1 h-full">
                <div className='flex gap-14 mb-8'>
                    <CapacityWidget comp='plant-screen' customHook={useCapacityWidget}/>
                    <KpiWidget/>
                </div>

                <div className='flex-1 min-h-0'>
                    <SiteGraphWidget/>
                </div>
            </div>
        </Suspense>
    )
}
