import { Suspense, lazy, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useCapacityWidget } from './components/capacityWidget/useCapacityWidget';
import { useSelector } from 'react-redux';
import { sharedStateSelector } from '../../features/sharedState/sharedSlice';
import { checkNegative, screenBottomGap } from '../../shared/utils/utils';
import './plantMonthly.css';
import { useKpiMonthly } from './components/kpiMonthlyWidget/useKpiMonthlyWidget';

const CapacityWidget = lazy(() =>
    import("../../shared/components/capacityWidget").then((module) => ({
        default: module.CapacityWidget,
    }))
);

const MonthlyKpiWidget = lazy(() =>
    import("../../shared/components/monthlyKpiWidget").then((module) => ({
        default: module.MonthlyKpiWidget,
    }))
);

const ColumnChartWidget = lazy(() =>
    import("./components/siteMonthlyGraphWidget").then((module) => ({
        default: module.ColumnChartWidget,
    }))
);

export const PlantMonthly = () => {
    const { colors } = useSelector(sharedStateSelector)
    const [availableHeight, setAvailableHeight] = useState(0);
    const divRef = useRef(null);

    useLayoutEffect(() => {
        document.body.style.backgroundColor = colors?.backgroundColor;
    }, [colors]);

    const calculateAvailableHeight = () => {
        if (divRef.current) {
            const elementBottom = divRef.current.getBoundingClientRect().bottom;
            const viewportHeight = window.innerHeight;
            setAvailableHeight(availableHeight => checkNegative(availableHeight + ((viewportHeight - elementBottom) - screenBottomGap())));
        }
    }

    useMemo(() => {
        if (!divRef.current)
            return

        //initial calculation
        calculateAvailableHeight();

        // Re-calculate available height on window resize
        window.addEventListener('resize', calculateAvailableHeight);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', calculateAvailableHeight);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [divRef.current]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <div className="grid grid-cols-12">
                <div className='col-span-12 flex gap-14 mb-8'>
                    <CapacityWidget comp='plant-ams' customHook={useCapacityWidget} />
                    <MonthlyKpiWidget customHook={useKpiMonthly} />
                </div>
                <div className='col-span-12' ref={divRef} style={{ height: availableHeight }}>
                    <ColumnChartWidget height={availableHeight} />
                </div>
            </div>
        </Suspense>
    )
}
