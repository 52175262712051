import {CSSProperties} from "react";
import {ClassNamesProps} from "@emotion/react";

export const SkyfriLogo = ({style, className}: {style?: CSSProperties, className?: string}) => {
    return <svg
        style={style}
        className={className}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 140 47">
        <path
            d="M23.228 0.127197H19.4163V12.378C19.4163 13.994 18.5587 15.4794 17.1531 16.2874C15.7476 17.0954 14.0323 17.0954 12.6268 16.2874L1.98982 10.1679L0.0839844 13.4593L13.1032 20.9572C13.4725 21.1711 13.9251 21.1711 14.2944 20.9572L22.6324 16.1567C23.0017 15.9428 23.228 15.5506 23.228 15.1229V0.127197Z"
            fill="white"/>
        <path
            d="M40.6549 36.8322L42.5607 33.5408L31.9237 27.4094C30.5182 26.6014 29.6606 25.1161 29.6606 23.5001C29.6606 21.8841 30.5182 20.3988 31.9237 19.5908L42.5607 13.4594L40.6549 10.168L27.6356 17.6658C27.2664 17.8797 27.04 18.2718 27.04 18.6996V28.3006C27.04 28.7284 27.2664 29.1205 27.6356 29.3344L40.6549 36.8322Z"
            fill="white"/>
        <path
            d="M0.0839844 33.5288L1.98982 36.8202L12.6268 30.6889C14.0323 29.8809 15.7476 29.8809 17.1531 30.6889C18.5587 31.4969 19.4163 32.9822 19.4163 34.5982V46.849H23.228V31.9246C23.228 31.4612 22.9778 31.0216 22.5729 30.7958L14.3539 26.0547C13.9489 25.8171 13.4487 25.8171 13.0437 26.0547L0.0839844 33.5288Z"
            fill="white"/>
        <path
            d="M74.1851 29.061C74.1851 33.0179 71.1953 36.3093 64.9418 36.3093C58.855 36.3093 54.2333 33.1486 53.9951 27.54H58.1284C58.3428 30.7008 60.4869 32.7802 64.9418 32.7802C68.6224 32.7802 70.0518 31.1523 70.0518 29.2392C70.0518 26.2924 67.9316 25.7339 63.024 24.5219C59.129 23.5475 54.9242 22.0622 54.9242 17.3449C54.9242 12.9365 58.3309 10.4768 63.7506 10.4768C69.2061 10.4768 73.1726 13.3167 73.518 18.4143H69.3848C69.0036 15.5387 67.193 14.0059 63.7506 14.0059C60.7966 14.0059 59.0218 15.111 59.0218 16.9528C59.0218 19.662 61.3564 20.1373 64.8227 21.0047C70.159 22.3712 74.1851 23.5119 74.1851 29.061Z"
            fill="white"/>
        <path
            d="M80.6891 10.9998V25.1874L87.9193 18.0817H92.398L85.6561 24.6646L93.1246 35.7985H88.7412L83.1428 27.0649L80.6771 29.3938V35.8104H77.0322V10.9998H80.6891Z"
            fill="white"/>
        <path
            d="M103.416 36.9035C102.404 39.6484 101.26 41.8229 97.8535 41.8229C96.8052 41.8229 95.9476 41.6447 95.2448 41.4783V38.7097H95.5545C97.0792 38.9117 98.2346 38.8166 99.0327 37.8423C99.6878 37.0461 100.176 35.6559 99.5211 33.9923L93.0889 18.0698H97.0197L101.987 31.6277H102.058L106.62 18.0698H110.515L103.416 36.9035Z"
            fill="white"/>
        <path
            d="M117.841 16.1328V18.0696H121.105V21.0521H117.841V35.7864H114.16V21.0521H111.623V18.0696H114.16V15.8119C114.16 12.069 115.756 10.75 118.675 10.75C119.58 10.75 120.378 10.8569 121.105 10.9876V13.9702C118.746 13.8751 117.841 14.1127 117.841 16.1328Z"
            fill="white"/>
        <path
            d="M133.587 17.7608V21.1948H133.516C130.002 20.6007 127.191 22.9653 127.191 26.7439V35.7983H123.546V18.0697H127.191V21.3968H127.262C128.203 19.1748 129.656 17.6538 131.991 17.6538C132.622 17.6538 133.063 17.6895 133.587 17.7608Z"
            fill="white"/>
        <path
            d="M135.922 13.1504C135.922 11.9027 136.97 11.2373 137.971 11.2373C139.043 11.2373 140.055 11.8908 140.055 13.1504C140.055 14.398 139.043 15.0872 137.971 15.0872C136.958 15.0872 135.922 14.398 135.922 13.1504ZM139.805 18.0697V35.7865H136.16V18.0697H139.805Z"
            fill="white"/>
    </svg>
}