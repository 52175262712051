import { useLayoutEffect } from 'react';
import { AggregateScreen } from '../pages/aggregateScreen/aggregateScreen.component';
import "./index.css";
import { useSelector } from 'react-redux';
import { sharedStateSelector } from '../features/sharedState/sharedSlice';

function App() {
  const { colors } = useSelector(sharedStateSelector)

  useLayoutEffect(() => {
    document.body.style.backgroundColor = colors?.backgroundColor;
  }, [colors]);

  return <AggregateScreen />;
}

export default App;
