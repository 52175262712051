import {FC, useContext, useEffect, useState} from "react"
import {useHeaderWidget} from "./useHeader"
import Clock from "react-live-clock"
import {useSelector} from "react-redux"
import {sharedStateSelector} from "../../features/sharedState/sharedSlice"
import {DropdownButton} from "./components/dropdownButton"
import "./header.css"
import {RolesMap} from "../../shared/interfaces"
import {logout} from "../../shared/utils/authUtils";
import {AppContext} from "../../providers/AppContext";

interface Props {
    roles: RolesMap
}

export const Header: FC<Props> = (props: Props) => {
    const {plantName} = useContext(AppContext);
    const {roles} = props

    const {colors} = useSelector(sharedStateSelector)

    const {logo, userName} = useHeaderWidget()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [resizeTimeout, setResizeTimeout] = useState<NodeJS.Timeout | null>(null);

    useEffect(() => {
        const handleResize = () => {
            if (resizeTimeout) {
                clearTimeout(resizeTimeout);
            }
            setResizeTimeout(setTimeout(() => {
                setWindowWidth(window.innerWidth);
            }, 1000));
        };

        window.addEventListener('resize', handleResize);
        return () => {
            if (resizeTimeout) {
                clearTimeout(resizeTimeout);
            }
            window.removeEventListener('resize', handleResize);
        };
    }, [resizeTimeout]);

    useEffect(() => {
        if (!plantName) return
        const parentDiv = document.querySelector('.ticker-container');
        const childDiv = document.querySelector('.ticker-content');
        const childWidth = childDiv?.clientWidth + 30;
        const parentWidth = parentDiv?.clientWidth;
        document?.documentElement?.style?.setProperty('--scroll-distance', ` ${-1 * (childWidth - parentWidth)}px`);
    }, [plantName, windowWidth]);

    const checkStringLen = (str: string) => str?.length >= 30

    return (
        <div
            className="flex justify-between text-white my-8"
            style={{backgroundColor: colors?.backgroundColor}}
        >
            <div className="col-span-4 flex items-center">
                <div className="grid grid-flow-col auto-cols-max">
                    <div className="col-span-2 flex items-center">
                        {/* Client Logo */}
                        {logo &&
                            <img
                                className="sm:pr-3 md:pr-6 pr-14 clientLogo"
                                alt="client-logo"
                                src={logo}
                            />
                        }
                    </div>

                    <div className="flex items-center plantName">
                        <div className={`${checkStringLen(plantName) ? "ticker-container" : ""}`}>
                            <div className={`${checkStringLen(plantName) ? "ticker-scroll" : ""}`}>
                                <div className="ticker-content font-bold">
                                    {plantName}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="grid grid-flow-col">
                <div className="row-span-2 pr-8 self-center sm:pr-2">
                    <p className="font-bold flex justify-end clockSize">
                        <Clock format={"HH:mm"} ticking={true}/>
                    </p>
                </div>
                <div className="row-span-2 self-center z-50">
                    <DropdownButton logOut={logout} userName={userName} roles={roles}/>
                </div>
            </div>
        </div>
    )
}
